<template>
  <div class="subject-index">
    <route-component/>
  </div>
</template>

<script>
import RouteComponent from "@/components/routerComponent/RouteComponent.vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "SubjectIndex",
  components: {RouteComponent},
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
</script>

<style lang="less" scoped>
.subject-index {
  width: 100%;
  height: 100%;
  background: url("~@/assets/image/timetable/timetable_background.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
</style>
